.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}
pre {
  padding: 10px;
  overflow: auto;
  line-height: 1.45;
  background-color: #ecf1f5;
  border-radius: 6px;
}

.toggle {
  position: relative;
  height: 100px;
  width: 100px;
}

.toggle:before {
  box-shadow: 0;
  border-radius: 84.5px;
  background: #fff;
  position: absolute;
  margin-left: -36px;
  margin-top: -36px;
  opacity: 0.2;
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
}

.toggle input:checked ~ .label {
  color: rgba(255, 0, 0, 0.8);
}

.toggle .button {
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 0 15px 25px -4px rgba(0, 0, 0, 0.5),
    inset 0 -3px 4px -1px rgba(0, 0, 0, 0.2),
    0 -10px 15px -1px rgba(255, 255, 255, 0.6),
    inset 0 3px 4px -1px rgba(255, 255, 255, 0.2),
    inset 0 0 5px 1px rgba(255, 255, 255, 0.8),
    inset 0 20px 30px 0 rgba(255, 255, 255, 0.2);
  border-radius: 68.8px;
  position: absolute;
  background: #eaeaea;
  margin-left: -34.4px;
  margin-top: -34.4px;
  display: block;
  height: 68.8px;
  width: 68.8px;
  left: 50%;
  top: 50%;
}

.toggle .label {
  transition: color 300ms ease-out;
  line-height: 101px;
  text-align: center;
  position: absolute;
  font-weight: 700;
  font-size: 28px;
  display: block;
  opacity: 0.9;
  height: 100%;
  width: 100%;
  color: rgba(0, 0, 0, 0.9);
}

.toggle input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.toggle input:active ~ .button {
  filter: blur(0.5px);
  box-shadow: 0 12px 25px -4px rgba(0, 0, 0, 0.4),
    inset 0 -8px 30px 1px rgba(255, 255, 255, 0.9),
    0 -10px 15px -1px rgba(255, 255, 255, 0.6),
    inset 0 8px 25px 0 rgba(0, 0, 0, 0.4),
    inset 0 0 10px 1px rgba(255, 255, 255, 0.6);
}

.toggle input:active ~ .label {
  font-size: 26px;
  color: rgba(0, 0, 0, 0.45);
}

.toggle input:checked ~ .button {
  filter: blur(0.5px);
  box-shadow: 0 10px 25px -4px rgba(0, 0, 0, 0.4),
    inset 0 -8px 25px -1px rgba(255, 255, 255, 0.9),
    0 -10px 15px -1px rgba(255, 255, 255, 0.6),
    inset 0 8px 20px 0 rgba(0, 0, 0, 0.2),
    inset 0 0 5px 1px rgba(255, 255, 255, 0.6);
}

.toggle input:checked ~ .label {
  color: rgba(0, 0, 0, 0.8);
}

.btn-hover:hover {
  transform: translateY(-3px);
}

.btn-primary:hover {
  background-color: #67aeff !important;
}

@media (max-width: 768px) {
  .footer {
    display: none;
  }
}
.pane.pane-top {
  background-color: #202429;
  padding-left: 5px !important;
  padding-right: 5px !important;
  justify-content: center !important;
}

.editor-lang {
  padding: 10px !important;
}

.editor-text {
  text-align: center;
  background-color: rgb(49 48 51 / 87%);
  color: rgba(250, 230, 194, 0.972);
  font-size: 1.2em;
  line-height: 2;
  justify-content: center;
}

.output-pane {
  left: 0;
  border: 0px solid;
  position: absolute;
  height: 50%;
  width: 100%;
  background-color: white;
  padding-bottom: 20px;
}

.footer {
  background-color: rgb(4, 11, 15) !important;
  position: fixed;
  bottom: 0;
  height: 36px;
  color: rgb(246, 225, 188);
  text-align: center;
  line-height: 2.5;
  justify-content: center !important;
}
